import "./App.css";
import { Join, Button, Alert } from "react-daisyui";
import React, { useRef, useState, useEffect, useCallback } from "react";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import * as utils from "./Utils";
import Subtitle from "./components/Subtitle";
import Thinking from "./components/status/Thinking";
import CameraModal from "./components/CameraModal";
import RecognitionIndicator from "./components/status/RecognitionIndicator";
import ConfigDrawer from "./components/ConfigDrawer";
import avatarBackground from "./assets/img/avatarBackground.png";
import {
  FaMicrophone,
  FaCirclePause,
  FaCirclePlay,
  FaRotate,
  FaCircleStop,
  FaGear
} from "react-icons/fa6";

import "preline/preline";


export default function App() {
  const avatarConfig = useRef(null);
  const avatarSynthesizer = useRef(null);
  const avatarVideoFormat = useRef(null);
  const speechSynthesisConfig = useRef(null);
  const langchainSocket = useRef(null);
  const speechRecognitionConfig = useRef(null);
  const speechRecognizer = useRef(null);
  const ttsSpeakingVoice = useRef("en-US-JennyMultilingualV2Neural");
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const removeVideoDiv = useRef(null);
  const cameraRef = useRef(null);
  const speakingThreads = useRef(0);
  const peerConnection = useRef(null);
  const canvasRef = useRef(null);
  const tmpCanvasRef = useRef(null);
  const audioConfig = useRef(null);
  const previousAnimationFrameTimestamp = useRef(0);
  const autoDetectSourceLanguageConfig = useRef(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("Not Ready");
  const [avatarEnabled, setAvatarEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [iceCredentials, setIceCredentials] = useState("");
  const [sessionActive, setSessionActive] = useState(false);
  const [cameraEnable, setCameraEnable] = useState(false);
  const [avatarCaption, setAvatarCaption] = useState(null);
  const [isThinking, setIsThinking] = useState(false);
  const [webexToken, setWebexToken] = useState("");
  const [notification, setNotification] = useState("");


  const refreshPage = () => {
    window.location.reload();
  };


  const makeBackgroundTransparent = useCallback((timestamp) => {
    // Throttle the frame rate to 30 FPS to reduce CPU usage
    if (timestamp - previousAnimationFrameTimestamp.current > 30) {
      const video = videoRef.current;
      const tmpCanvas = tmpCanvasRef.current;
      const tmpCanvasContext = tmpCanvas.getContext("2d", {
        willReadFrequently: true,
      });

      tmpCanvasContext.drawImage(
        video,
        0,
        0,
        video.videoWidth,
        video.videoHeight
      );
      if (video.videoWidth > 0) {
        let frame = tmpCanvasContext.getImageData(
          0,
          0,
          video.videoWidth,
          video.videoHeight
        );

        for (let i = 0; i < frame.data.length / 4; i++) {
          let r = frame.data[i * 4 + 0];
          let g = frame.data[i * 4 + 1];
          let b = frame.data[i * 4 + 2];

          if (g - 150 > r + b) {
            // Set alpha to 0 for pixels that are close to green
            frame.data[i * 4 + 3] = 0;
          } else if (g + g > r + b) {
            // Reduce green part of the green pixels to avoid green edge issue
            let adjustment = (g - (r + b) / 2) / 3;
            r += adjustment;
            g -= adjustment * 2;
            b += adjustment;
            frame.data[i * 4 + 0] = r;
            frame.data[i * 4 + 1] = g;
            frame.data[i * 4 + 2] = b;
            // Reduce alpha part for green pixels to make the edge smoother
            let a = Math.max(0, 255 - adjustment * 4);
            frame.data[i * 4 + 3] = a;
          }
        }

        const canvas = canvasRef.current;
        const canvasContext = canvas.getContext("2d");
        canvasContext.putImageData(frame, 0, 0);
      }

      previousAnimationFrameTimestamp.current = timestamp;
    }

    window.requestAnimationFrame(makeBackgroundTransparent);
  }, []);

  // Callback function to handle errors from TTS Avatar API
  const error_cb = useCallback((result) => {
    let cancellationDetails = SpeechSDK.CancellationDetails.fromResult(result);
    console.log(`Error occurred in the Avatar service: ${cancellationDetails.errorDetails}`);
    setAvatarEnabled(false);
  }, []);

  // Callback function to handle the response from TTS Avatar API
  const complete_cb = useCallback((result) => {
    console.log(result);
    const sdp = result.properties.getProperty(
      SpeechSDK.PropertyId.TalkingAvatarService_WebRTC_SDP
    );

    if (sdp === undefined) {
      console.log(`Failed to get remote SDP. The avatar instance is temporarily unavailable. Result ID: ${result.resultId}`);
    }

    setTimeout(() => {
      peerConnection.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(sdp))));
    }, 2000);
  }, []);

  // Connect to the Azure TTS Avatar API via ICE
  useEffect(() => {
    if (connectionStatus === "Ready" && avatarEnabled) {
      console.log("Starting ICE Connection...");
      peerConnection.current = new RTCPeerConnection(iceCredentials);

      peerConnection.current.addEventListener("iceconnectionstatechange", (event) => {
        console.log(event.currentTarget.iceConnectionState);
        switch (event.currentTarget.iceConnectionState) {
          case "connected":
            setConnectionStatus("Connected");
            break;
          case "disconnected":
            setConnectionStatus("Not Connected");
            break;
          default:
            console.log(event.currentTarget.iceConnectionState);
            break;
        }
      }
      );

      peerConnection.current.addEventListener("track", (event) => {
        switch (event.track.kind) {
          case "audio":
            audioRef.current.srcObject = event.streams[0];
            break;
          case "video":
            videoRef.current.srcObject = event.streams[0];

            removeVideoDiv.current.hidden = true;
            canvasRef.current.hidden = false;

            videoRef.current.addEventListener("play", () => {
              removeVideoDiv.current.style.width = videoRef.current.videoWidth + "px";
              window.requestAnimationFrame(makeBackgroundTransparent);
            });

            videoRef.current.onplaying = () => {
              console.log(`WebRTC ${event.track.kind} channel connected.`);
              setTimeout(() => {
                setSessionActive(true);
                setLoading(false);
              }, 5000);
            };
            break;
          default:
            console.log(event.track.kind);
            // Handle other cases if necessary
            break;
        }
      });

      peerConnection.current.addTransceiver("video", { direction: "sendrecv" });
      peerConnection.current.addTransceiver("audio", { direction: "sendrecv" });

      avatarSynthesizer.current.startAvatarAsync(peerConnection.current, complete_cb, error_cb).then((r) => {
        if (r.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
          console.log(`[${new Date().toISOString()}]: Avatar Started`);

          const userQuery = "Hi, tell me about yourself.";
          langchainSocket.current.send(
            JSON.stringify({
              type: "agentCall",
              input: userQuery,
              session_id: sessionId,
            })
          );
        } else {
          console.log(`[${new Date().toISOString()}]: Failed to Avatar Start`);
          if (r.reason === SpeechSDK.ResultReason.Canceled) {
            let cancellationDetails = SpeechSDK.CancellationDetails.fromResult(r);
            if (
              cancellationDetails.reason === SpeechSDK.CancellationReason.Error
            ) {
              console.log(`[${new Date().toISOString()}]: ${cancellationDetails.errorDetails}`);
            }
            console.log(`[${new Date().toISOString()}]: ${cancellationDetails.errorDetails}`);
          }
        }
      })
        .catch((error) => {
          console.log(`[${new Date().toISOString()}]: ${error.message}`);
        });


    }
  }, [avatarEnabled, complete_cb, connectionStatus, error_cb, iceCredentials, makeBackgroundTransparent, sessionId]);

  const handleStopRecognition = () => {
    setIsListening(false);
    speechRecognizer.current.stopContinuousRecognitionAsync(() => {
      console.log(`[${new Date().toISOString()}]: Speech recognition stopped.`);
    });
  };

  // Establish the backend websocket connection
  useEffect(() => {
    langchainSocket.current = new WebSocket(process.env.REACT_APP_LANGHAIN_WS);

    langchainSocket.current.onopen = () => {
      if (langchainSocket.current.readyState === WebSocket.OPEN) {
        console.log(`Langchain Websocket Activated...`)
        langchainSocket.current.send(JSON.stringify({ type: "token" }));
      }
    };

    langchainSocket.current.onmessage = (message) => {
      const data = JSON.parse(message.data);

      if (data.type === "agentResponse") {
        speak(data.result['output']);
      } else if (data.type === "setToken") {
        setIceCredentials(data.iceServers);
        setSessionId(data.session_id);
        setConnectionStatus("Ready");
      } else {
        console.log(data);
      }
    };
  }, [])

  // Set Avatar configuration and begin establish the backend websocket connection
  useEffect(() => {
    try {

      // Set up the TTS Avatar API configuration
      const supportedLanguages = ["en-US", "es-US", "de-DE", "zh-CN", "ar-AE", "ja-JP", "pt-BR", "fr-CA"];

      speechSynthesisConfig.current = SpeechSDK.SpeechConfig.fromSubscription(process.env.REACT_APP_SPEECH_KEY, process.env.REACT_APP_SPEECH_REGION);

      speechSynthesisConfig.current.speechSynthesisVoiceName = ttsSpeakingVoice.current;

      // Set up the avatar to crop the video feed to fit into a mode
      avatarVideoFormat.current = new SpeechSDK.AvatarVideoFormat();
      avatarVideoFormat.current.setCropRange(new SpeechSDK.Coordinate(600, 0), new SpeechSDK.Coordinate(1320, 1080));

      avatarConfig.current = new SpeechSDK.AvatarConfig("lisa", "casual-sitting", avatarVideoFormat.current);

      // Set the background color of the avatar to green screen
      avatarConfig.current.subtitleType = "soft_embedded";
      avatarConfig.current.backgroundColor = "#00FF00FF";

      // Set up the Avatar Synthesizer
      avatarSynthesizer.current = new SpeechSDK.AvatarSynthesizer(speechSynthesisConfig.current, avatarConfig.current);

      speechRecognitionConfig.current = SpeechSDK.SpeechConfig.fromSubscription(process.env.REACT_APP_SPEECH_KEY, process.env.REACT_APP_SPEECH_REGION);

      autoDetectSourceLanguageConfig.current = SpeechSDK.AutoDetectSourceLanguageConfig.fromLanguages(supportedLanguages);

      audioConfig.current = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();

      speechRecognitionConfig.current.setProperty(SpeechSDK.PropertyId.SpeechServiceConnection_LanguageIdMode, "Continuous");

      speechRecognizer.current = SpeechSDK.SpeechRecognizer.FromConfig(speechRecognitionConfig.current, autoDetectSourceLanguageConfig.current, audioConfig.current);

    } catch (err) {
      console.error(err);
    }
  }, []);

  // Stop all TTS streams
  const stopSpeaking = () => {
    avatarSynthesizer.current.stopSpeakingAsync(() => {
      speakingThreads.current = 0;
    },
      (error) => {
        speakingThreads.current = 0;
        console.error(`Error occurred while stopping the Avatar: [ ${error} ]`);
      }
    );
  };

  // Speak text using TTS Avatar API
  const speak = (text, endingSilenceMs = 0) => {
    speakingThreads.current++;

    let speakText = text.replace(/[\*\#]/g, "");

    setAvatarCaption({ role: "agent", content: speakText });
    const ssml = utils.generateSSML(speakText, ttsSpeakingVoice.current, endingSilenceMs);

    setIsThinking(false);
    avatarSynthesizer.current.speakSsmlAsync(ssml).then((result) => {
      if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
        speakingThreads.current--;
      } else {
        speakingThreads.current--;
        if (result.reason === SpeechSDK.ResultReason.Canceled) {
          let cancellationDetails = SpeechSDK.CancellationDetails.fromResult(result);
          if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
            console.error(`Error occurred while speaking the SSML: [ ${cancellationDetails.errorDetails} ]`);
          };
        };
      };
    }).catch((error) => {
      console.error(`Error occurred while speaking the SSML: [ ${error} ]`);
    });
  };

  // Send user query to Langchain Websocket
  const queryLangchainAgent = (userQuery) => {
    try {
      // Stop recognition to avoid overlapping
      handleStopRecognition();
      setIsThinking(true);
      langchainSocket.current.send(
        JSON.stringify({
          type: "agentCall",
          input: userQuery,
          session_id: sessionId,
        }));

    } catch (err) {
      console.error(err);
    }
  };

  const handleSetWebexToken = (e) => {
    e.preventDefault();
    if (webexToken) {
      langchainSocket.current.send(JSON.stringify({ type: "webexToken", token: webexToken }));
      setNotification("");
    } else {
      setNotification("Please enter a valid Webex Access Token");
    }
  };

  const handleStopSpeaking = (e) => {
    e.preventDefault();
    stopSpeaking();
  };

  const handleStartRecognition = (e) => {
    e.preventDefault();
    console.log("Starting Recognition...")

    try {
      speechRecognizer.current.startContinuousRecognitionAsync(() => {
        console.log(`[${new Date().toISOString()}]: Speech Recognition Started`);
        setIsListening(true);
      });

      speechRecognizer.current.recognized = (s, e) => {
        if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
          // Trim whitespace
          let userQuery = e.result.text.trim();

          //TODO: Figure out where teh "Play" comes from. It seems that if the user doesn't say anything, it picks up "Play."
          // Return if user query is blank or picks up something it cannot recognize.
          if (userQuery !== "Play.") {
            // Send recognized text to API / Langchain
            setAvatarCaption({ role: "user", content: userQuery });
            queryLangchainAgent(userQuery);
          };

        } else {
          console.log(e.result.reason);
        }
      };

    } catch (err) {
      console.log(err)
    }

  };

  const handleEnableCamera = (e) => {
    e.preventDefault();
    setCameraEnable(!cameraEnable);
  };

  const handleDrawerOpen = (e) => {
    e.preventDefault();
    setDrawerOpen(!drawerOpen);
  };

  const handleOpenCamera = (e) => {
    e.preventDefault();
    setModalOpen(!modalOpen);
  };

  // Start the Avatar once the Langchain socket and backend are ready
  const startAvatar = () => {
    if (langchainSocket.current.readyState === WebSocket.OPEN && !sessionActive) {
      console.log("Backend ready...");
      setAvatarEnabled(true);
      setLoading(true);
    } else {
      console.log("Backend not ready...");
      setTimeout(startAvatar, 3000);
    }
  };

  const handleStartAvatarButton = (e) => {
    e.preventDefault();
    startAvatar();
  };

  return (
    <div className="flex flex-col h-screen dark text-foreground z-0">
      {notification && <Alert
        status="error"
        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>}
      >
        <span>{notification}</span>
      </Alert>}
      <ConfigDrawer
        drawerStatus={drawerOpen}
        handleDrawerOpen={handleDrawerOpen}
        cameraEnable={cameraEnable}
        handleEnableCamera={handleEnableCamera}
        webexToken={webexToken}
        setWebexToken={setWebexToken}
        handleSetWebexToken={handleSetWebexToken}
      />
      <div className="flex flex-col md:flex-row 2xl:flex-row flex-grow h-full overflow-hidden">
        <CameraModal
          modalOpen={modalOpen}
          setAvatarCaption={setAvatarCaption}
          speak={speak}
          ref={cameraRef}
          cameraEnable={cameraEnable}
          handleOpenCamera={handleOpenCamera} />
        <div className="flex items-center justify-center flex-grow overflow-hidden h-full">
          <div id="remoteVideo" ref={removeVideoDiv} className="h-full">
            <video id="video" ref={videoRef} autoPlay playsInline></video>
          </div>
          <div
            id="canvasContainer"
            style={{ backgroundImage: `url(${avatarBackground})` }}
            className="h-full bg-no-repeat bg-cover relative"
          >
            <canvas
              id="canvas"
              width="720"
              height="1080"
              hidden="hidden"
              ref={canvasRef}
              className="bg-transparent h-full"
            ></canvas>
            <canvas
              id="tmpCanvas"
              width="720"
              height="1080"
              hidden="hidden"
              ref={tmpCanvasRef}
            ></canvas>
            {isListening && sessionActive && <RecognitionIndicator />}
            {isThinking && <Thinking />}
            {avatarCaption && <Subtitle message={avatarCaption} />}
          </div>
          <audio id="remoteAudio" ref={audioRef} autoPlay></audio>
        </div>
      </div>
      <div className="flex flex-col justify-around p-6">
        <Join className="justify-center" responsive="true">
          <Button
            color="success"
            disabled={!connectionStatus === "Ready" || avatarEnabled}
            onClick={handleStartAvatarButton}
            startIcon={<FaCirclePlay />}
            className="join-item"
            animation="true"
            loading={loading}
            size="sm"
          >
            Start Avatar
          </Button>
          <Button
            color="secondary"
            disabled={isListening || !sessionActive}
            onClick={handleStartRecognition}
            startIcon={<FaMicrophone />}
            className="join-item"
            animation="true"
            size="sm"
          >
            Start Recognition
          </Button>
          <Button
            color="warning"
            disabled={!isListening}
            onClick={handleStopRecognition}
            startIcon={<FaCirclePause />}
            className="join-item"
            animation="true"
            size="sm"
          >
            Pause Recognition
          </Button>
          <Button
            color="error"
            disabled={speakingThreads.current === 0}
            onClick={handleStopSpeaking}
            startIcon={<FaCircleStop />}
            className="join-item"
            animation="true"
            size="sm"
          >
            Stop Speaking
          </Button>
          <Button
            color="accent"
            onClick={refreshPage}
            startIcon={<FaRotate />}
            className="join-item"
            animation="true"
            size="sm"
          >
            Restart Session
          </Button>
          <Button
            color="info"
            onClick={handleDrawerOpen}
            startIcon={<FaGear />}
            className="join-item"
            animation="true"
            size="sm"
          >
            Show Config
          </Button>
        </Join>
      </div>
    </div>
  );
}
