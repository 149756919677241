import axios from "axios";

const htmlEncode = (text) => {
    const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '*': ''
    };


    return String(text).replace(/[&<>"'/]/g, (match) => entityMap[match])
}


const getQuickReply = () => {
    var quickReplies = ['Let me take a look.', 'Let me check.', 'One moment, please.']
    return quickReplies[Math.floor(Math.random() * quickReplies.length)]
};

const generateSSML = (text, voice, endingSilenceMs) => {
    let ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts='http://www.w3.org/2001/mstts' xml:lang='en-US'>
    <voice name='${voice}'><mstts:leadingsilence-exact value='0'/><mstts:express-as style="friendly">${htmlEncode(text)}
    </mstts:express-as>${endingSilenceMs > 0 ? `<break time='${endingSilenceMs}ms'/>` : ''}</voice></speak>`;

    return ssml;
};

const logMessage = (message) => {
    const timeStamp = new Date().toISOString();
    console.log(`[${timeStamp}]: ${message}`);
};

const makeAxiosCall = (path, payload) => {
    return new Promise((resolve, reject) => {
        axios.post(
            path,
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": `${process.env.NEXT_PUBLIC_TOKEN_CODE}`,
                },
            }
        ).then((response) => {
            return resolve(response.data);
        }
        ).catch((error) => {
            return reject(error);
        })
    })
}

export { htmlEncode, getQuickReply, generateSSML, logMessage, makeAxiosCall };