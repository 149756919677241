import { Drawer, Menu, Toggle, Input, Button } from "react-daisyui";

const ConfigDrawer = ({
    drawerStatus,
    handleDrawerOpen,
    handleEnableCamera,
    cameraEnable,
    webexToken,
    setWebexToken,
    handleSetWebexToken
}) => {
    return (
        <Drawer
            open={drawerStatus}
            side={
                <Menu className="p-2 w-80 h-full bg-base-200 text-base-content z-50">
                    <Menu.Title>Configuration</Menu.Title>
                    <Menu.Item>
                        <div>
                            Enable Vision:
                            <Toggle
                                color="primary"
                                checked={cameraEnable}
                                onChange={handleEnableCamera}
                            />

                        </div>
                    </Menu.Item>
                    <Menu.Item className="">
                        <div>
                            Webex Key:
                            <Input
                                label="Webex Access Token"
                                placeholder="Webex Access Token"
                                size="sm"
                                value={webexToken}
                                onChange={(e) => setWebexToken(e.target.value)}
                            />

                        </div>
                        <div>
                            <Button
                                onClick={handleSetWebexToken}
                                color="primary"
                                size="sm"
                            >Set Token</Button>
                        </div>


                    </Menu.Item>
                </Menu>
            }
            onClickOverlay={handleDrawerOpen}
            className="z-50"
        ></Drawer>
    );
};

export default ConfigDrawer;
