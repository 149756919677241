import React from 'react';
import { Loading } from "react-daisyui";


const Thinking = () => {
    return (
        <div className="absolute align-middle top-0 right-1 mt-2 ml-2 text-red-900 text-md">
            <Loading variant="dots" size="sm" /> Thinking...
        </div>
    )

}

export default Thinking;